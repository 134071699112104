<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        카지노 배팅 내역
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
        <b-badge
          variant="light-info"
          style="font-size: small; margin-right: 3px"
        >
          실시간
        </b-badge>
        <b-badge
          variant="primary"
          style="font-size: x-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <div class="ml-2 mr-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1"
          >
            <label style="width: 41px">상태</label>
            <b-form-select
              id="select-type"
              v-model="fetchSelected1"
              :options="option1"
              class="divSelecter font-small-3"
              size="sm"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="5"
            lg="5"
            md="5"
            sm="12"
            class=""
          >
            <b-form-group
              class="font-small-3"
              label="배팅일시"
              label-for="period"
              label-cols-md="2"
            >
              <b-input-group
                name="period"
                class="calendar-size font-small-3"
              >
                <b-form-datepicker
                  v-model="periodFrom"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="시작일"
                  aria-controls="example-input"
                  size="sm"
                  :max="periodTo"
                />
                <div class="pl-1 pr-1">
                  ~
                </div>
                <b-form-datepicker
                  v-model="periodTo"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="종료일"
                  aria-controls="example-input"
                  size="sm"
                  :min="periodFrom"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="ml-2 mr-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="col-sm-12"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="font-small-3"
              label="검색옵션"
              label-for="search"
              label-cols-md="2"
            >
              <b-input-group
                class="align-self-center"
                size="sm"
                name="search"
              >
                <b-col
                  cols=""
                  md=""
                  class=""
                >
                  <b-form-select
                    v-model="searchOption"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="searchOptionTitle"
                    size="sm"
                    :clearable="false"
                    class="per-page-selector d-inline-block"
                    style="margin-left: -1rem"
                  />
                </b-col>
                <b-form-input
                  v-model="inputValue"
                  type="search"
                  placeholder="Type to Search"
                  style="width: 200px"
                  @keyup.enter="fetchData(fetchSelected1)"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="ml-1"
                    @click="fetchData(fetchSelected1)"
                  >
                    검색
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchGameCasinoTransaction"
        :fields="fields1"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        style="text-align: center; font-size: small"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template
          #cell(no)="data"
          size="sm"
          style="text-align: center; max-width: 1rem"
        >
          {{ data.index + 1 }}
        </template>
        <template #cell(site)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem"
          >
            {{ data.item.site }}
          </div>
        </template>
        <template #cell(id)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem"
          >
            {{ data.item.id }}
          </div>
        </template>
        <template
          #cell(type)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.type }}
          </div>
        </template>
        <template
          #cell(username)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.username }}
          </div>
        </template>
        <template
          #cell(amount)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.amount }}
          </div>
        </template>
        <template
          #cell(refererId)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.refererId }}
          </div>
        </template>
        <template
          #cell(processedAt)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 12rem"
          >
            {{ data.item.processedAt }}
          </div>
        </template>
        <template
          #cell(targetId)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.targetId }}
          </div>
        </template>
        <template
          #cell(targetUsername)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.targetUsername }}
          </div>
        </template>
        <template
          #cell(targetBalance)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.targetBalance }}
          </div>
        </template>
        <template
          #cell(detailId)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.detailId }}
          </div>
        </template>
        <template #cell(round)="data">
          <div
            v-b-tooltip.hover.bottom.v-danger="data.item.round"
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ truncateText(data.item.round, 15) }}
          </div>
        </template>
        <template
          #cell(title)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 9rem"
          >
            {{ data.item.title }}
          </div>
        </template>
        <template
          #cell(detailType)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.detailType }}
          </div>
        </template>
        <template
          #cell(vendor)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.vendor }}
          </div>
        </template>
        <template
          #cell(createdAt)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.createdAt }}
          </div>
        </template>
        <template
          #cell(updatedAt)="data"
        >
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.updatedAt }}
          </div>
        </template>
      </b-table>
      <!--          <pre>selected: {{ selectedGroupIds }}</pre>-->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormCheckbox,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_GAME_CASINO_TRANSACTION,
} from '@/store/game/action'
import * as moment from 'moment-timezone'
import router from '@/router'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    const today = moment().format('YYYY-MM-DD')
    const oneWeekAgo = moment().subtract(2, 'days').format('YYYY-MM-DD')

    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      userIp: localStorage.getItem('ip'),
      fetchSelected1: '',
      option1: [
        { value: '', text: '전체' },
        { value: 'win', text: '당첨' },
        { value: 'bet', text: '배팅' },
        { value: 'cancel', text: '취소' },
      ],
      perPage: 30,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      notReadCnt: 0,
      currentPage: 1,
      sortBy: 'processedAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      periodFrom: oneWeekAgo,
      periodTo: today,
      searchOption: 'username',
      inputValue: '',
      searchOptionTitle: [
        { value: 'username', text: '유저명' },
      ],
      fields1: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: 'id', thClass: 'col1',
        },
        {
          key: 'id', sortable: false, label: 'id', thClass: 'col1',
        },
        {
          key: 'type', sortable: false, label: 'type', thClass: 'col1',
        },
        {
          key: 'username', sortable: false, label: 'username', thClass: 'col5',
        },
        {
          key: 'amount', sortable: false, label: 'amount', thClass: 'col3',
        },
        {
          key: 'refererId', sortable: false, label: 'refererId', thClass: 'col2',
        },
        {
          key: 'processedAt', sortable: false, label: 'processedAt', thClass: 'col2',
        },
        {
          key: 'targetId', sortable: false, label: 'targetId', thClass: 'col3',
        },
        {
          key: 'targetUsername', sortable: false, label: 'targetUsername', thClass: 'col3',
        },
        {
          key: 'targetBalance', sortable: false, label: 'targetBalance', thClass: 'col3',
        },
        {
          key: 'detailId', sortable: false, label: 'detailId', thClass: 'col3',
        },
        {
          key: 'round', sortable: false, label: 'round', thClass: 'col3',
        },
        {
          key: 'title', sortable: false, label: 'title', thClass: 'col3',
        },
        {
          key: 'detailType', sortable: false, label: 'detailType', thClass: 'col6',
        },
        {
          key: 'vendor', sortable: false, label: 'vendor', thClass: 'col6',
        },
      ],
    }
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData(this.fetchSelected1)
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
    fetchSelected1(newVal) {
      this.fetchSelected1 = newVal
      this.fetchData(newVal)
    },
    periodFrom() {
      this.fetchData(this.fetchSelected1)
    },
    periodTo() {
      this.fetchData(this.fetchSelected1)
    },
  },
  created() {
    this.init()
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameCasinoTransaction: 'fetchGameCasinoTransaction',
    }),
  },
  mounted() {
    this.fetchData(this.fetchSelected1)
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameCasinoTransaction: FETCH_GAME_CASINO_TRANSACTION,
    }),
    init() {
    },
    async fetchData(fetchSelected) {
      // const mySite = this.userData.site
      const periodFrom = this.periodFrom ? `${moment(this.periodFrom).format('YYYY.MM.DD')} 00:00:00` : ''
      const periodTo = this.periodTo ? `${moment(this.periodTo).format('YYYY.MM.DD')} 23:59:59` : ''
      const { searchOption } = this
      const { inputValue } = this
      // console.log('siteSelected:::', this.siteSelected)
      // console.log('periodFrom:::', this.periodFrom)
      // console.log('periodTo:::', this.periodTo)
      // console.log('searchOption:::', this.searchOption)
      // console.log('inputValue:::', this.inputValue)
      // console.log('type:::', this.fetchSelected1)
      await this.$fetchGameCasinoTransaction({
        site: this.siteSelected,
        periodFrom,
        periodTo,
        searchOption,
        inputValue,
        type: fetchSelected,
      })
      this.totalRows = this.fetchGameCasinoTransaction.length
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // Sleep function
    async sleep(ms) {
      const wakeUpTime = Date.now() + ms
      while (Date.now() < wakeUpTime) {}
    },
    getTotal(field) {
      return this.fetchGameCasinoTransaction.reduce((acc, item) => acc + item[field], 0)
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.divSelecter {
  width: 180px;
}
</style>
